import router from '@/router'
// eslint-disable-next-line object-curly-newline
import { reactive, getCurrentInstance, watch, toRefs } from 'vue'

export const getContentType = contentType => {
  const contentPaths = {
    'App\\Models\\Report':      { singular: 'Report',                         plural: 'Reports',                listSlug: 'reports',          singleSlug: 'report', },
    'App\\Models\\Event':       { singular: 'Event',                          plural: 'Events',                 listSlug: 'events',           singleSlug: 'event', },
    'App\\Models\\Gallery':     { singular: 'Gallery',                        plural: 'Galleries',              listSlug: 'galleries',        singleSlug: 'gallery', },
    'App\\Models\\Playlist':    { singular: 'Recordings & notes',             plural: 'Recordings & notes',     listSlug: 'recordings-notes', singleSlug: 'recording', },
    'App\\Models\\File':        { singular: 'File',                           plural: 'Files',                  listSlug: 'files',            singleSlug: 'file', },
    'Wordpress\\good-practice': { singular: 'Case study',                     plural: 'Case studies',           listSlug: 'case-studies',     singleSlug: 'case-study', },
    'Wordpress\\research-blog': { singular: 'Research blog article',          plural: 'Research blog articles', listSlug: 'research-blog',    singleSlug: 'research-blog-article', },
    'Wordpress\\news':          { singular: 'News',                           plural: 'News',                   listSlug: 'news',             singleSlug: 'news-article', },
    'Wordpress\\ask-dwg':       { singular: 'AskDWG article',                 plural: 'AskDWG articles',        listSlug: 'ask-dwg',          singleSlug: 'ask-dwg-article', },
  }

  return contentPaths[contentType]
}

export const getCategoryImage = (category, contentType) => {
    const categoryImages = require('@/data/category-images.json')

  if(category) category = category.toLowerCase()

  let categoryImage = categoryImages[category]
  if(!categoryImage) {
    categoryImage = categoryImages[contentType]
  }

  if (!categoryImage) {
    categoryImage = categoryImages[getContentType(contentType).singleSlug.toLowerCase()]
  }

  return '/img/category-images/'+categoryImage
}

export const getContentItemRoute = contentItem => {
  const contentType = getContentType(contentItem.content_type)
  return {
    name: contentType.singleSlug, params: { slug: contentItem.slug }
  }
}

export const isObject = obj => typeof obj === 'object' && obj !== null

export const isToday = date => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

export const publishedDate = (dateString, withDayName = false) => {
  const date = new Date(dateString)
  const opts = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }

  if(withDayName) opts.weekday = 'long'

  return date.toLocaleDateString('en-US', opts)
}

const getRandomFromArray = array => array[Math.floor(Math.random() * array.length)]

// ? Light and Dark variant is not included
// prettier-ignore
export const getRandomBsVariant = () => getRandomFromArray(['primary', 'secondary', 'success', 'warning', 'danger', 'info'])

export const isDynamicRouteActive = route => {
  const { route: resolvedRoute } = router.resolve(route)
  return resolvedRoute.path === router.currentRoute.path
}

// Thanks: https://medium.com/better-programming/reactive-vue-routes-with-the-composition-api-18c1abd878d1
export const useRouter = () => {
  const vm = getCurrentInstance().proxy
  const state = reactive({
    route: vm.$route,
  })

  watch(
    () => vm.$route,
    r => {
      state.route = r
    },
  )

  return { ...toRefs(state), router: vm.$router }
}

// Turn the page load indicator off
export const turnOffPageLoadIndicator = () => {
  const pageLoadIndicator = document.querySelector('#page-load-indicator')
  if (pageLoadIndicator) {
    pageLoadIndicator.classList.remove('active')
  }
}

/**
 * This is just enhancement over Object.extend [Gives deep extend]
 * @param {target} a Object which contains values to be overridden
 * @param {source} b Object which contains values to override
 */
// export const objectExtend = (a, b) => {
//   // Don't touch 'null' or 'undefined' objects.
//   if (a == null || b == null) {
//     return a
//   }

//   Object.keys(b).forEach(key => {
//     if (Object.prototype.toString.call(b[key]) === '[object Object]') {
//       if (Object.prototype.toString.call(a[key]) !== '[object Object]') {
//         // eslint-disable-next-line no-param-reassign
//         a[key] = b[key]
//       } else {
//         // eslint-disable-next-line no-param-reassign
//         a[key] = objectExtend(a[key], b[key])
//       }
//     } else {
//       // eslint-disable-next-line no-param-reassign
//       a[key] = b[key]
//     }
//   })

//   return a
// }
